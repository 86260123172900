import { Box, Container, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { theme } from '../@chakra-ui/gatsby-plugin/theme';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import { ServiceCard } from '../components/ServiceCard';
import '../global.css';
import Assembly from '../images/assembly.svg';
import Guidance from '../images/guidance.svg';
import Manufacturing from '../images/manufacturing.svg';
import Project from '../images/project.svg';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = () => {
    return (
        <Layout>
            <Flex scrollMarginTop="4" id="start" position="relative" alignSelf={['center', 'stretch']}>
                <Flex ml={[0, 'auto']}>
                    <StaticImage src="../images/backdrop.jpg" alt="tło" placeholder="dominantColor" />
                </Flex>
                <Box
                    visibility={['visible', 'visible']}
                    position="absolute"
                    top="0"
                    bottom="0"
                    right="0"
                    w={['100%', '600px']}
                    background={[
                        `linear-gradient(0deg, ${theme.brandColors.primary} 0%, transparent 90%, transparent 100%)`,
                        `linear-gradient(90deg, ${theme.brandColors.primary} 0%, transparent 40%, transparent 100%)`,
                    ]}
                />
                <Flex
                    position="absolute"
                    pl={['4', 'calc((100vw - 1280px + 32px)/2)']} // 100vw - container.xl - 4 for container.padding
                    pb={['4', '0']}
                    top="0"
                    left="0"
                    bottom="0"
                    right={['0', '600']}
                    direction="column"
                    gap={['1', '4']}
                    justify={['flex-end', 'center']}
                    background={['none', theme.brandColors.primary]}
                >
                    <Heading as="h3" size={['lg', 'xl', 'xl']} color={[theme.colors.white, theme.colors.white]}>
                        Balustrady
                    </Heading>
                    <Heading as="h3" size={['lg', 'xl', 'xl']} color={[theme.colors.white, theme.colors.white]}>
                        Poręcze schodowe
                    </Heading>
                    <Heading as="h3" size={['lg', 'xl', 'xl']} color={[theme.colors.white, theme.colors.white]}>
                        Balkoniki francuskie
                    </Heading>
                </Flex>
            </Flex>
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['10', '0']}>
                <Flex gap="10" direction="column">
                    <Heading
                        id="oferta"
                        scrollMarginTop="4"
                        mx={['12', '0']}
                        as="h2"
                        size="2xl"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Oferta
                    </Heading>

                    <Stack scrollMarginTop="4" direction={['column', 'row']} spacing="10" align="center">
                        <ServiceCard
                            icon={Guidance}
                            title="Doradztwo"
                            description="Pomagamy wybrać rozwiązanie najlepsze dla konkretnej nieruchomości"
                        />
                        <ServiceCard
                            icon={Project}
                            title="Projekt"
                            description="Wykonujemy kompleksowy projekt uzgodnionego rozwiązania"
                        />
                        <ServiceCard
                            icon={Manufacturing}
                            title="Wykonanie"
                            description="Wykonujemy zamówioną konstrukcję zgodnie z projektem"
                        />
                        <ServiceCard
                            icon={Assembly}
                            title="Montaż"
                            description="Transportujemy i montujemy wykonaną konstrukcję"
                        />
                    </Stack>
                    <QuestionCard defaultExpanded title="Działalność firmy">
                        <Heading as="h4" size="md">
                            Balustrady ze stali nierdzewnej, poręcze, konstrukcje zewnętrzne i wewnętrzne
                        </Heading>
                        <Text mt={['2']}>
                            Oferujemy szeroki wybór produktów, w tym balustrady ze stali nierdzewnej, poręcze schodowe w
                            budynkach mieszkalnych i jednorodzinnych, balustrady zewnętrzne i wewnętrzne oraz bramy
                            garażowe.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Wysokiej jakości materiały
                        </Heading>
                        <Text mt={['2']}>
                            Wszystkie nasze produkty są wykonane z wysokiej jakości stali, co zapewnia nie tylko
                            elegancki wygląd, ale także trwałość i bezpieczeństwo.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Doświadczenie
                        </Heading>
                        <Text mt={['2']}>
                            Działamy od ponad 30 lat, zapewniamy kompleksowe i indywidualne podejście do każdego
                            projektu.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Obszar działania
                        </Heading>
                        <Text mt={['2']}>
                            Świadczymy usługi na terenie całego Śląska, w tym w takich miejscowościach jak Katowice,
                            Siemianowice Śląskie, Piekary Śląskie, Chorzów, Tychy, Mikołów, Gliwice, Rybnik oraz
                            okolice.
                            <br />
                            <br />
                            <Link textDecoration="underline" href="/kontakt">
                                Skontaktuj się z nami
                            </Link>
                            , aby poznać więcej szczegółów.
                        </Text>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;
