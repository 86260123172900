import { Flex, Heading, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../@chakra-ui/gatsby-plugin/theme';

interface Props {
    icon: string;
    title: string;
    description: string;
}

export const ServiceCard = ({ description, icon, title }: Props) => {
    return (
        <Flex
            flex="1 1 100%"
            direction={['row', 'column']}
            alignSelf="stretch"
            alignItems={'center'}
            mx={['2', '0']}
            px="6"
            py="6"
            gap="8"
            border="1px"
            borderRadius="xl"
            borderColor={theme.brandColors.border}
            backgroundColor={theme.brandColors.background}
            shadow="0px 6px 15px rgba(26, 27, 56, 0.25)"
        >
            <Icon w="24" h="auto" as={icon as any} />
            <Flex direction="column" gap={[0, 8]}>
                <Heading as="h3" fontSize="24px" textAlign={['left', 'center']}>
                    {title}
                </Heading>
                <Text fontSize="lg" textAlign={['left', 'center']}>
                    {description}
                </Text>
            </Flex>
        </Flex>
    );
};
